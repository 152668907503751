import React from 'react';
import {
  Box,
  FlexBox,
  FormField,
  Spacer,
  TextH2
} from '@directsoftware/ui-kit-web-admin';
import { useSelector } from 'react-redux';
import validator from 'validator';
import { selectCheckoutState } from '../../../redux/slices/checkout';

const ContactInfo = ({ updateCheckoutState, isLynnbrook, isAuthenticate }) => {
  const checkoutState = useSelector(selectCheckoutState);
  const [isFocusedDate, setIsFocusedDate] = React.useState(false);

  const isError = key => {
    if (checkoutState.lynnbrookErrors?.length) {
      if (checkoutState.lynnbrookErrors.includes(key)) return true;
    }

    return false;
  };

  const hasEmailErrors = () => {
    return (
      isError('customerEmail') ||
      checkoutState.validationErrors.includes('email')
    );
  };

  return (
    <>
      <TextH2>Your Contact Info</TextH2>
      <Spacer size="s" />
      <Spacer size="xxs" />
      {isLynnbrook ? (
        <FlexBox gap="s">
          <Box flex="1">
            <FormField
              labelText="First Name"
              labelHtmlFor="customerFirstName"
              inputType="text"
              inputProps={{
                onChange: e =>
                  updateCheckoutState({ customerFirstName: e.target.value }),
                inputWidth: 'auto',
                value: checkoutState.customerFirstName,
                name: 'customerFirstName',
                isError: isError('customerFirstName')
              }}
            />
          </Box>
          <Box flex="1">
            <FormField
              labelText="Last Name"
              labelHtmlFor="customerLastName"
              inputType="text"
              inputProps={{
                onChange: e =>
                  updateCheckoutState({ customerLastName: e.target.value }),
                inputWidth: 'auto',
                value: checkoutState.customerLastName,
                name: 'customerLastName',
                isError: isError('customerLastName')
              }}
            />
          </Box>
        </FlexBox>
      ) : (
        <FormField
          labelText="Name"
          labelHtmlFor="customerName"
          inputType="text"
          inputProps={{
            onChange: e =>
              updateCheckoutState({ customerName: e.target.value }),
            inputWidth: 'auto',
            value: checkoutState.customerName,
            name: 'customerName',
          }}
        />
      )}
      <FlexBox gap="s">
        <Box flex="1">
          <FormField
            labelText="Email"
            labelHtmlFor="customerEmail"
            inputType="text"
            inputProps={{
              onChange: e =>
                updateCheckoutState({ customerEmail: e.target.value }),
              type: 'email',
              value: checkoutState.customerEmail,
              name: 'customerEmail',
              isError: hasEmailErrors(),
              description: hasEmailErrors()
                ? 'Please enter a valid email'
                : null,
              onBlur: () => {
                const validationErrorArray = !validator.isEmail(
                  checkoutState.customerEmail
                )
                  ? [...checkoutState.validationErrors, 'email']
                  : checkoutState.validationErrors.filter(
                      err => err !== 'email'
                    );
                updateCheckoutState({
                  validationErrors: validationErrorArray
                });
              }
            }}
          />
        </Box>
        <Box flex="1">
          <FormField
            labelText="Phone"
            labelHtmlFor="customerTelephone"
            inputType="phone"
            inputProps={{
              onChange: value =>
                updateCheckoutState({ customerTelephone: value }),
              inputWidth: 'auto',
              value: checkoutState.customerTelephone,
              name: 'customerTelephone',
              isError: isError('customerTelephone')
            }}
          />
        </Box>
        {isAuthenticate && (
          <Box flex="1">
            <FormField
              labelText="Date of Birth"
              labelHtmlFor="customerDateOfBirth"
              inputType="date"
              inputProps={{
                onDateChange: selectedDate =>
                  updateCheckoutState({ customerDateOfBirth: selectedDate }),
                date: checkoutState.customerDateOfBirth,
                isError: isError('customerDateOfBirth'),
                isFocused: isFocusedDate,
                onFocusChange: ({ focused }) => setIsFocusedDate(focused)
              }}
            />
          </Box>
        )}
      </FlexBox>
      <Spacer size="l" />
    </>
  );
};

export default ContactInfo;
